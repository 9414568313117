import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import {
  getSidebarStatus,
  getSize,
  setSidebarStatus,
  setSize,
  setLanguage,
} from '@/utils/local_storage';
import { getLocale } from '@/lang';
import store from '@/store';

export interface MyAppState {
  sidebar: {
    opened: boolean;
    withoutAnimation: boolean;
  };
  language: string;
  size: string;
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements MyAppState {
  sidebar = {
    opened: getSidebarStatus() !== 'closed',
    withoutAnimation: false,
  };

  language = getLocale();
  size = getSize() || 'medium';

  @Action
  ToggleSideBar(withoutAnimation: boolean) {
    this.TOGGLE_SIDEBAR(withoutAnimation);
  }

  @Action
  CloseSideBar(withoutAnimation: boolean) {
    this.CLOSE_SIDEBAR(withoutAnimation);
  }

  @Action
  SetLanguage(language: string) {
    this.SET_LANGUAGE(language);
  }

  @Action
  SetSize(size: string) {
    this.SET_SIZE(size);
  }

  @Mutation
  private TOGGLE_SIDEBAR(withoutAnimation: boolean) {
    this.sidebar.opened = !this.sidebar.opened;
    this.sidebar.withoutAnimation = withoutAnimation;
    if (this.sidebar.opened) {
      setSidebarStatus('opened');
    } else {
      setSidebarStatus('closed');
    }
  }

  @Mutation
  private CLOSE_SIDEBAR(withoutAnimation: boolean) {
    this.sidebar.opened = false;
    this.sidebar.withoutAnimation = withoutAnimation;
    setSidebarStatus('closed');
  }

  @Mutation
  private SET_LANGUAGE(language: string) {
    this.language = language;
    setLanguage(this.language);
  }

  @Mutation
  private SET_SIZE(size: string) {
    this.size = size;
    setSize(this.size);
  }
}

export const AppModule = getModule(App);
