








































import i18n from '@/lang/index';
import AddSchoolInfo from './add_school_info.vue';

import { Component, Vue, Prop } from 'vue-property-decorator';
import { deleteSchoolInfoApi } from '@/api/school_api'
import { UserStoreModule } from '@/store/UserModule';
import indexStore from '@/store/index'
@Component({name: 'schoolInfo', components: { AddSchoolInfo }})
export default class SchoolInfo extends Vue {
  @Prop() addPermission!: string;
  @Prop() delPermission!: string;
  @Prop() editPermission!: string;
  @Prop({default: false}) isEdit!: boolean;
  // 获取学校资讯数据
  @Prop({default: ''}) schoolInfoData!: any;
  // 获取学校id
  @Prop({default: ''}) fkInstitutionId!: number | string;
  get isPermission(){
    return (str: string)=>{
      if(UserStoreModule.isSupperPermission) return true;
      return this.$route.meta.permission.indexOf(str) >= 0
    }
  }
  get cusTitleList(){
    if(this.schoolInfoData && this.schoolInfoData.length){
      const obj: {[key:string]: any} = {};
      for(let i = 0, lng = this.schoolInfoData.length; lng > i ; i++){
        let fkInfoTypeId = this.schoolInfoData[i].fkInfoTypeId;
        if(!obj[fkInfoTypeId]){
          obj[fkInfoTypeId] = {
            title: this.schoolInfoData[i].title,
            typeName: this.schoolInfoData[i].typeName,
            typeKey: this.schoolInfoData[i].typeKey,
          }
        }
      }
      return obj;
    }
    return {};
  }
  get getUrl() {
    return (url: any) => {
      return url? UserStoreModule.userInfo.publicFilesUrl + url: ''
    }
  }
  // 需要编辑的数据
  editInfoData: any = {};
  hanleAddSchoolInfo() {
    (this.$refs.addSchoolInfo as any).showDetail = true;
    this.editInfoData = {};
  }
  // 编辑
  editSchoolInfo(data: any) {
    (this.$refs.addSchoolInfo as any).showDetail = true;
    this.editInfoData = Object.assign({}, data);
  }
  // 刷新
  handleRefresh() {
    this.$emit('refreshSchoolInfo')
  }
  // 删除
  delSchoolInfo(row: any) {
    const id = Number(row.id)
    this.$confirm(
        i18n.t('deleteConfirmMsg', {name: i18n.t('data')}) as string,
        i18n.t('operaConfirmTips') as string,
      {
        confirmButtonText: i18n.t('confirm') as string,
        cancelButtonText: i18n.t('cancel') as string,
        type: 'warning',
      })
      .then(async () => {
        await deleteSchoolInfoApi(id);
        this.$message.success(i18n.t('delSucc') as string);
        this.handleRefresh();
      }).catch(()=>{
        this.$message.info(i18n.t('delCancel') as string);
      });
  }
 
}
