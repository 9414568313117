























































































import i18n from '@/lang'
import AddSchool from '@/view/admin/school_module/components/add_school.vue';
import schoolLogo from '@/components/upLoad/index.vue';
import {Vue, Component, Prop, Inject, PropSync} from 'vue-property-decorator';
import { deleteSchoolApi,uploadMediaPiaApi,deleteSchoolMediaApi, delAnnualMeetingApi } from '@/api/school_api';
import { LayoutStoreModule } from '@/store/LayoutModule';
import { saveMediaApi } from '@/api/school_api';
import { UserStoreModule } from '@/store/UserModule';
import indexStore from '@/store/index'

@Component({name: 'schoolIntroduce', components: { AddSchool,schoolLogo }})
export default class SchoolIntroduce extends Vue{
  // 介绍数据
  @Prop({ default: ''}) introduceData!: any;
  // logo数据
  @Prop({ default: ''}) logoFile!: any;
  @Prop({ default: ''}) editPermission!: string;    // 编辑的权限字符
  @Prop({ default: ''}) deletePermission!: string; // 删除的权限字符
  @PropSync('isEdit') _isEdit!: boolean;
  @Inject({
    from: 'schoolType',
    default: 'normal'
  }) schoolType!: 'normal' | 'annual'
  // 是否编辑
  // 保存接口
  saveApi: any = saveMediaApi;
  // 图片上传接口
  uploadPicApi = uploadMediaPiaApi;
  // 附件
  mediaData: any = {};
  get isAnnualMember(){
    return UserStoreModule.isAnnualMember;
  }
  get isPermission(){
    return (str: string)=>{
      if(UserStoreModule.isSupperPermission) return true;
      return this.$route.meta.permission.indexOf(str) >= 0
    }
  }
  get characters(){
    if(this.introduceData.characters){
      return JSON.parse(this.introduceData.characters);
    }
    return [];
  }
  get getUrl() {
    return (url: any) => {
      return url? UserStoreModule.userInfo.publicFilesUrl + url: ''
    }
  }
  created() {
    if(this.logoFile) {
      this.mediaData = this.logoFile[0]
    }
  }
  // 编辑
  handlePicEdit() {
   (this.$refs.schoolLogo as any).showUpLoad();
  }

  // 删除logo
  deleteLogo(data:any) {
    if(!data) return;
    const id = data[0].id;
    this.$confirm(
      i18n.t('deleteConfirmMsg', {name: i18n.t('data')}) as string,
      i18n.t('operaConfirmTips') as string,
    {
      confirmButtonText: i18n.t('confirm') as string,
      cancelButtonText: i18n.t('cancel') as string,
      type: 'warning',
    })
    .then(async () => {
      await deleteSchoolMediaApi(id);
      this.$message.success(i18n.t('delSucc') as string);
      this.handleRefresh();
    }).catch(()=>{
      this.$message.info(i18n.t('delCancel') as string);
    });
  }
  // 查看
  handleCheck() {
    const guid = this.introduceData.guid;
    const routeUrl = this.$router.resolve({name: 'schooolDetail', params: {id: guid}})
    window.open(routeUrl.href, '_blank')
  }
  // 学校介绍编辑
  handleIntroudeEdit() {
     (this.$refs.addSchool as any).showDetail = true;
  }
  // 编辑学校详情
  editSchoolDetail() {
    this._isEdit = !this._isEdit;
  }
 
  // 更新
  handleRefresh() {
    this.$emit('refreshParent')
  }
  // 删除
  delSchoolDetail() {
    const guid = String(this.$route.params.id);
    this.$confirm(
        i18n.t('deleteConfirmMsg', {name: i18n.t('data')}) as string,
        i18n.t('operaConfirmTips') as string,
      {
        confirmButtonText: i18n.t('confirm') as string,
        cancelButtonText: i18n.t('cancel') as string,
        type: 'warning',
      })
      .then(async () => {
        await (this.schoolType == 'normal' ?  deleteSchoolApi(guid) : delAnnualMeetingApi(guid));
        this.$message.success(i18n.t('delSucc') as string);
        this.schoolType == 'normal' && LayoutStoreModule.delAsideMenubySchool(guid);
        this.close();
      }).catch(()=>{
        this.$message.info(i18n.t('delCancel') as string);
      });
  }
  close() {
    LayoutStoreModule.removeDeletePage(LayoutStoreModule.navItemActive);
    this.$router.push({name: 'adminSchoolList'})
  }
  // 关闭上传
  closeUpLoad(){
    const el:any=this.$refs.schoolLogo;
    el.closeUpLoad();
  }
  // 上传附件关闭
  uploadSuccess(data:any){
    this.closeUpLoad();
    this.handleRefresh();
  }
}
