
























































import i18n from '@/lang/index';
import mediaUpload from '@/components/upLoad/index.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { saveMediaApi, uploadMediaPiaApi,uploadAttachApi,deleteSchoolMediaApi } from '@/api/school_api';
import {checkPreview,isPreview,downloadImage } from '@/utils/index';
import { UserStoreModule } from '@/store/UserModule';

@Component({name: 'schoolMedia', 
components: {mediaUpload}
})
export default class SchoolMedia extends Vue {
  @Prop({ default: ''}) addPermission!: string;    // 新增的权限字符
  @Prop({ default: ''}) deletePermission!: string; // 删除的权限字符
  @Prop({default: false}) isEdit!: boolean;
  // 获取附件数据
  @Prop({ default: []}) mediaDetailList!: any;
  // 获取学校id
  @Prop({ default: ''}) fkInstitutionId!: number | string;
  // 图片上传接口
  uploadPicApi = uploadMediaPiaApi;
  // 附件接口
  uploadAttachApi = uploadAttachApi;
  // 组件名称
  typeKey:any = '';
  // 保存接口
  saveApi: any = saveMediaApi;
  // 新增为空
  mediaId: number | string = '';
  // 附件数据
  mediaData: any = '';
  // 图片下载地址
  imgUrl: any = '';

  // 添加附件
  handleAddMedia() {
    (this.$refs.upload as any).showUpLoad();
    this.mediaId = '';
  }
  get isPermission(){
    return (str: string)=>{
      return this.$route.meta.permission.indexOf(str) >= 0
    }
  }
  get isAnnualMember(){
    return UserStoreModule.isAnnualMember;
  }
  // 附件是否是图片
  get isPreview(){
    return (data:any)=>{
       return isPreview(data);
    }
  }
  // 浏览图片
  getFile(filePath:any,fileNameOrc:any, typeKey: string){
    if(typeKey.indexOf('_pdf') === -1){
      filePath=filePath? UserStoreModule.userInfo.publicFilesUrl + filePath: ''
    }
    checkPreview(filePath,fileNameOrc,typeKey)
  }
   // 关闭上传
  closeUpLoad(){
    const el:any=this.$refs.upload;
    el.closeUpLoad();
  }
  // 上传附件关闭
  uploadSuccess(data:any){
    this.closeUpLoad();
    this.$emit('refresh')
  }
  
  // 下载附件/图片
  downLoadFiles(filePath: string, fileNameOrc: string, typeKey: string) {
    downloadImage(filePath, fileNameOrc)
  }
  // 删除
  delSchoolMedia(id: any) {
    this.$confirm(
        i18n.t('deleteConfirmMsg', {name: i18n.t('data')}) as string,
        i18n.t('operaConfirmTips') as string,
      {
        confirmButtonText: i18n.t('confirm') as string,
        cancelButtonText: i18n.t('cancel') as string,
        type: 'warning',
      })
      .then(async () => {
        await deleteSchoolMediaApi(id);
        this.$message.success(i18n.t('delSucc') as string);
       this.$emit('refresh')
      }).catch(()=>{
        this.$message.info(i18n.t('delCancel') as string);
      });
  }
  
}
