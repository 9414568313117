



























































import i18n from '@/lang/index';
import saveBtnGroup from '@/components/saveBtnGroup.vue';
import Editor from '@/components/Editor/index.vue';

import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  getSchoolInfoSelectApi,
  addSchoolInfoApi,
  editSchoolInfoApi, } from '@/api/school_api';
import { InstitutionInfoVo } from '@/interface/school';
import { UserStoreModule } from '@/store/UserModule';
@Component({name: 'addSchoolInfo',components: { saveBtnGroup, Editor }})
export default class AddSchoolInfo extends Vue {
  // 获取学校id
  @Prop({default: ''}) fkInstitutionId!: number | string;
  // 有无学校资讯id，有编辑无新增
  @Prop({default: ''}) schoolInfoId!: number | string;
  @Prop({default: ''}) editInfoData: any;
  @Prop() cusTitleList:any;
  showDetail: boolean = false;

  schoolInfoData: InstitutionInfoVo = {
    description: '',
    fkInfoTypeId: '',
    id: '',
    fkInstitutionId: '',
    fkMediaId: '',
    title: '',
  };
  formRules: any = {
    description: [
      {
        required: true,
        message: i18n.t('descEmpty'),
        trigger: 'blur',
      }
    ],
    title: [
      {
        required: true,
        message: i18n.t('title'),
        trigger: 'blur',
      }
    ],
    fkInfoTypeId: [
      {
        required: true,
        message: i18n.t('schoolInfoTypeEmpty'),
        trigger: 'change',
      }
    ],
  };
  
  // 展示编辑器工具时不抖
  showEditor: boolean = false
  // 富文本工具
  toolbar=['bold italic underline alignleft aligncenter alignright hr bullist numlist link image media preview table emoticons backcolor forecolor fullscreen fontsizeselect code'];
  option: any = [];
  get isAnnualMember(){
    return UserStoreModule.isAnnualMember
  }
  @Watch('showDetail')
  handleShow(val: any) {
    if(val) {
      this.getSchoolInfoSelect();
      let timerId: any;
      clearTimeout(timerId);
      
      timerId = setTimeout(() => {
        this.showEditor = true
      }, 0);
      if(this.editInfoData.id) {
        this.schoolInfoData = Object.assign({}, this.editInfoData);
      }
    }else {
      this.clear();
    }
  }
  get defaultTitle(){
    if(!this.schoolInfoData.fkInfoTypeId) return ''
    const item = this.option.find((item:any) => item.id == this.schoolInfoData.fkInfoTypeId);
    return item ? item.typeName : '';
  }

  // 取消
  cancelForm() {
    this.showDetail = false;
  }
  
  // 保存
  submitForm(formStr: any) {
    this.schoolInfoData.fkInstitutionId = this.fkInstitutionId;
    const el: any = this.$refs[formStr];
    el.validate((valid: any) => {
      if(valid) {
        (this.$refs.saveBtnRef as any).loadingTrue();
        if(this.editInfoData.id) {
          this.editSchoolInfo();
        }else {
          this.addSchoolInfo();
        }
      }
    })
  }
  // 编辑学校资讯
  editSchoolInfo() {
    editSchoolInfoApi(this.schoolInfoData).then(()=> {
      this.$message({ type: 'success', message: i18n.t('editSuccess') as string });
      this.showDetail = false;
      this.$emit('refresh');
      (this.$refs.saveBtnRef as any).loadingFalse();
    }).catch(err=>{
      this.$message.error(i18n.t('editFail') as string);
      (this.$refs.saveBtnRef as any).loadingFalse();
    })
  }
  // 新增学校资讯
  addSchoolInfo() {
    addSchoolInfoApi(this.schoolInfoData).then(()=> {
      this.$message({ type: 'success', message: i18n.t('addSuccess') as string });
      this.showDetail = false;
      this.$emit('refresh');
      (this.$refs.saveBtnRef as any).loadingFalse();
    }).catch(err=>{
      this.$message.error(i18n.t('addFail') as string);
      (this.$refs.saveBtnRef as any).loadingFalse();
    })
  }
  // 获取资讯类型下拉框
  async getSchoolInfoSelect() {
    const { data } = await getSchoolInfoSelectApi();
    this.option = data.data;
  }

  // 获取富文本内容
  getValue(val:any){
    this.schoolInfoData.description = val;
  }
  clear() {
    (this.$refs.schoolForm as any).resetFields();
    this.schoolInfoData = {
      description: '',
      fkInfoTypeId: '',
      id: '',
      fkInstitutionId: '',
      fkMediaId: '',
      title: ''
    };
  }
  schoolTypeChange(val:string){
    for (let key in this.cusTitleList){
      if(key == val && this.cusTitleList[key].title){
        this.schoolInfoData.title = this.cusTitleList[key].title;
        break;
      }
    }
  }
}
