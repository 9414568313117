






















































































































































import i18n from '@/lang';
import VueCropper from './vueCropper.vue';
import saveBtnGroup from '@/components/saveBtnGroup.vue';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { UpLoadData } from '@/interface/index';
import { debounce } from '@/utils/delayed';
import { UserStoreModule } from '@/store/UserModule';
import indexStore from '@/store/index'
import validateInput from '@/directive/validateInput';
@Component({
  name: 'upLoad',
  components: { VueCropper, saveBtnGroup }
})
export default class UpLoad extends Vue {
  // 限制上传个数
  @Prop({ type: Number, default: 5 }) limitNum?: number;
  // 判断是文件上传还是图片上传
  @Prop({ type: Boolean, default: true }) isPicture?: boolean;
  // 上传的时候是否需要ID传给后端
  @Prop({ type: Boolean, default: false }) isNeedId?: boolean;
  // 如果一个页面有多个上传，那么用这个区分
  @Prop({ type: String, default: '' }) uploadModule?: string;
  // 保存接口
  @Prop({ default: '' }) saveApi!: any;
  // 图片/文件上传路径
  @Prop({ type: String, default: '' }) upLoadUrl!: string;
  // 组件的名称
  @Prop({ default: '' }) title!: any;
  // 需要通过参数传的id
  @Prop({ default: '' }) otherId?: number | string
  // 是否logo上传
  @Prop({ default: false }) isLogo?: boolean;
  // 图片类型key
  @Prop({ default: '' }) picTypeKey: any;
  // pdf类文件类型key
  @Prop({ default: '' }) fileTypeKey: any;
  // 视频类型key
  @Prop({ default: '' }) vedioTypeKey: any;
  // 详情背景图key
  @Prop({ default: '' }) detailBgKey: any;
  // 地理位置背景图key
  @Prop({ default: '' }) locaotionBgKey: any;
  // 图片上传接口
  @Prop({ default: '' }) uploadPicApi: any;
  // 附件上传接口
  @Prop({ default: '' }) uploadAttachApi: any;
  // 编辑数据
  @Prop({ default: '' }) mediaData!: any;
  // 只保存原图
  @Prop({ default: false }) saveSourceOnly!: boolean;

  // 请求头
  headers = UserStoreModule.headers;
  // 显示隐藏
  dialogVisible: boolean = false;
  // 保存的参数
  attachmentForm: UpLoadData = {} as UpLoadData;
  // 是否是自动上传 // 默认是手动上传，即不需要剪辑的
  isAutoUpload: any = true;
  // loading
  loading: boolean = false;
  // 上传到后端的文件数组
  formArr: any = []
  // rule规则
  formRules: any = {
    fileNameOrc: [
      {
        required: true,
        message: i18n.t('fileNameIsEmpty'),
        trigger: 'click',
      },
    ],
  };
  // 上传类型
  uploadType: any = 0;
  // 上传选项
  uploadTypeOption: any = [
    { value: i18n.t('schoolFile'), key: 0 },
    { value: i18n.t('schoolPicture'), key: 1 },
    { value: i18n.t('schoolIntroductionVideo'), key: 2 },
    { value: i18n.t('detailsBackgroundImage'), key: 3 },
    { value: i18n.t('LocationBackgroundMap'), key: 4 },
  ]
  // 是否展示裁剪
  showCropper: boolean = false;
  // 裁剪图片地址
  corpperImg: any = '';
  // 需要裁剪图片名称
  ImagefileName: any = '';
  // 需要裁剪图片类型
  fileTypeOrc: any = '';
  // 截取后的图片数据
  cropperImgData: any = {};
  // 防抖
  debounceSubmitForm = debounce(this.submitForm, 500, true)
  // 如果显示类型选择才调用接口，否则写死key传给后端(key为后端需要的值，具体看对应文档需要穿的值)
  // 是附件还是视频
  attachFileTypeKey: any = '';

  // 取消
  cancelForm() {
    this.dialogVisible = false;
    this.uploadType = 0;
    // this.closeUpLoad();
    this.attachmentForm.remark = '';
  }
  get isAnnualMember() {
    return UserStoreModule.isAnnualMember;
  }
  get getUrl() {
    return (url: any) => {
      return url? UserStoreModule.userInfo.publicFilesUrl + url: ''
    }
  }
  // 打开
  showUpLoad() {
    this.dialogVisible = true;
    // 默认是pdf附件类
    this.uploadType = 0;
    this.attachFileTypeKey = this.fileTypeKey;
    this.isAutoUpload = true;
    if (this.isLogo) {
      this.isAutoUpload = false;
    }
  }
  // submit()后会实现
  // 自定义上传
  handleRequest(data: any) {
    let formdata = new FormData();
    formdata.append(data.filename, data.file)
    // 图片上传接口
    this.uploadPicApi(formdata).then((res: any) => {
      // this.corpperImg = this.getUrl(res.data.data[0].filePath)
      if(!this.isAutoUpload||this.isLogo){
        this.ImagefileName = res.data.data[0].fileNameOrc;
        this.fileTypeOrc = res.data.data[0].fileTypeOrc;
        // 弹出裁剪框
        this.handleCrop();
      }
      // 获取上传成功数据
      this.handleAvatarSuccess(res.data);
    })
  }

  // 成功获取图片
  handleAvatarSuccess(res: any) {
    if (this.isAutoUpload) {
      this.attachmentForm.typeKey = this.attachFileTypeKey
    } else {
      this.attachmentForm.typeKey = this.picTypeKey
    }
    let fkTableId: any = '';
    if (this.otherId) {
      fkTableId = this.otherId
    } else {
      fkTableId = this.$route.params.id;
    }
    if(!res.data){
      return this.$message.info(res.message);
    }
    const attachData = res.data.map((v: any) => {
      return {
        fileNameOrc: v.fileNameOrc,
        fileKey: v.fileKey,
        fkFileGuid: v.fileGuid,
        fkTableId: fkTableId,
        id: this.isNeedId ? v.id : '',
        fkTableName: v.fkTableName,
        indexKey: v.indexKey,
        remark: this.attachmentForm.remark,
        typeKey: this.attachmentForm.typeKey,
      };
    });
    this.attachmentForm = { ...attachData[0] };
    this.formArr.push(this.attachmentForm);
  }
  // 删除图片
  removeData(file: any, fileList: any) {
    if (file.response) {
      const id: any = file.response.data[0].id
      this.formArr.forEach((item: any, index: number) => {
        if (item.id === id) {
          this.formArr.splice(index, 1);
        }
      })
    }
  }

  // 自动上传判断格式
  fileBeforeUpload(file: any) {
    if (this.attachFileTypeKey === this.locaotionBgKey || this.attachFileTypeKey === this.detailBgKey) {
      return this.beforeAvatarUpload(file)
    } else {
      return this.beforeFileUpload(file)
    }
  }
  // 文件上传格式限制
  beforeFileUpload(file: any) {
    const typeFile = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'pdf'];
    const typeVideo = ['mp4', 'mov'];
    let types: any = [];
    if (this.attachFileTypeKey === this.vedioTypeKey) {
      types = typeVideo;
    } else {
      types = typeFile;
    }
    const fileName: any = file.name.substring(file.name.lastIndexOf('.') + 1)
    const isFile = types.includes(fileName.toLowerCase());
    const isLt50M = file.size / 1024 / 1024 < 50;
    if (!isFile) {
      this.$message.error(i18n.t('failUploadTip') as string);
      return false;
    }
    if (!isLt50M) {
      this.$message.error(i18n.t('instructionsTwo') as string);
      return false;
    }
    return isFile && isLt50M
  }
  // 图片上传格式限制
  beforeAvatarUpload(file: any) {
    const types = ['jpg', 'png', 'gif', 'jpeg'];
    // const isLt2M: any = file.size / 1024 / 1024 < 2;
    const isLtSmall5k = file.size / 1024 > 5;
    const isLt500k = file.size / 1024 < 500;
    const fileName: any = file.name.substring(file.name.lastIndexOf('.') + 1)
    const isImage = types.includes(fileName.toLowerCase());
    if (!isImage) {
      this.$message.error(i18n.t('failUploadTip') as string);
      return false;
    }
    if (!isLt500k || !isLtSmall5k) {
      this.$message.error(i18n.t('backgroundImageLimit') as string);
      return false;
    }
    // 检验通过后显示剪裁框
    return isImage && isLt500k && isLtSmall5k;
  }

  // 获取图片文件传截图图片，使用文件上传获取下载地址
  // 便于预览
  async getImgFiles(files: any) {
    let formdata = new FormData();
    // 二进制
    formdata.append('files', files)
    const res = await this.uploadPicApi(formdata);
    this.getCropperData(res.data);
    this.submitFileForm();
  }


  // 图片提交
  async submitForm() {
    
    if (!this.isAutoUpload) {
      if(Object.keys(this.attachmentForm).length == 0) {
        this.$message.error(i18n.t('fileNameIsEmpty')+'')
        return;
      }
      // 获取截取图片
      (this.$refs.vueCropperImg as any).uploadImg();
    } else {
      this.submitFileForm();
    }
  }
  // 提交
  async submitFileForm() {
    const upLoadForm: any = this.$refs.attachmentForm;
    upLoadForm.validate((valid: any) => {
      if (valid === true) {
        this.loadingTrue();
        this.saveApi(this.saveSourceOnly ? this.formArr.slice(1) : this.formArr).then((data: any) => {
          this.$message.success(i18n.t('saveSuss') as string);
          this.$emit('uploadSuccess', data, this.uploadModule);
          this.loadingFalse();
          this.dialogVisible = false;
        }).catch((err: any) => {
          this.loadingFalse();
          this.$message.info(i18n.t('saveFail') as string);
          this.dialogVisible = false;
        })
      }
    });
  }
  // loading为true
  loadingTrue() {
    this.loading = true;
  }
  // loading为false
  loadingFalse() {
    this.loading = false;
  }
  // 点击弹出剪裁框
  handleCrop() {
    this.showCropper = true;
  }
  // 选择图片还是附件
  // 触发下拉框更换才选择
  uploadTypeChange(val: any) {
    this.closeUpLoad();
    if (val === 1) {
      // 环境图
      this.isAutoUpload = false;
    } else if (val === 2) {
      // 视频
      this.isAutoUpload = true;
      this.attachFileTypeKey = this.vedioTypeKey;
    } else if (val === 3) {
      // 详情背景图
      this.isAutoUpload = true;
      this.attachFileTypeKey = this.detailBgKey;
    } else if (val === 4) {
      // 地理位置背景图
      this.isAutoUpload = true;
      this.attachFileTypeKey = this.locaotionBgKey;
    }
    else {
      this.isAutoUpload = true;
      this.attachFileTypeKey = this.fileTypeKey;
    }
  }
  // 清空数据
  closeUpLoad() {
    // 清除缓存的
    this.formArr = [];
    this.attachmentForm = {
      fileNameOrc: '',
      fileKey: '',
      fkFileGuid: '',
      fkTableId: '',
      fkTableName: '',
      id: '',
      indexKey: '',
      remark: this.attachmentForm.remark,
      typeKey: ''
    };
    if (this.$refs.fileUpload) {
      (this.$refs.fileUpload as any).clearFiles();
    }
    if (this.$refs.picUpload) {
      (this.$refs.picUpload as any).clearFiles();
    }
  }

  // 获取图片后手动提交
  handleChange(file: any, fileList: any) {
    const objectURL = URL.createObjectURL(file.raw);
    this.corpperImg = objectURL;
    (this.$refs.picUpload as any).submit();
  }
  // 获取新的裁剪图片展示
  getCropperData(data: any) {
    if (data.data) {
      this.corpperImg = this.getUrl(data.data[0].fileKey);
    }
    this.handleAvatarSuccess(data)
  }
}
