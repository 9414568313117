

















// Docs: https://www.tiny.cloud/docs/advanced/usage-with-module-loaders/
// Import TinyMCE
import 'tinymce/tinymce';
// Default icons are required for TinyMCE 5.3 or above
import 'tinymce/icons/default';
// Import themes
import 'tinymce/themes/silver';
import 'tinymce/themes/mobile';
// Any plugins you want to use has to be imported
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/print';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/tabfocus';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';
import TinymceEditor from '@tinymce/tinymce-vue'; // TinyMCE vue wrapper
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/app_module';
import { UserStoreModule } from '@/store/UserModule';
import EditorImageUpload, { TUploadObject } from './components/EditorImage.vue';
import { plugins, toolbar } from './config';
import request from '@/utils/request';

const defaultId = () =>
  'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '');
@Component({
  name: 'Tinymce',
  components: {
    EditorImageUpload,
    TinymceEditor,
  },
})
export default class extends Vue {
  @Prop({ required: true }) private value!: string;
  @Prop({ required: true }) private uploadUrl!: string;
  @Prop({ default: defaultId }) private id!: string;
  @Prop({ default: () => [] }) private toolbar!: string[];
  @Prop({ default: 'file edit insert view format table' }) private menubar!: string;
  @Prop({ default: '360px' }) private height!: string | number;
  @Prop({ default: 'auto' }) private width!: string | number;
  @Prop({default:''}) indexVal?:string|number;

  private hasChange = false;
  private hasInit = false;
  private fullscreen = false;
  private plugins = plugins;
  // https://www.tiny.cloud/docs/configure/localization/#language
  // and also see langs files under public/tinymce/langs folder
  private languageTypeList: { [key: string]: string } = {
    'en': 'en',
    'zh-cn': 'zh_CN',
  };

  get language() {
    return this.languageTypeList[AppModule.language];
  }

  get tinymceContent() {
    return this.value;
  }
  set tinymceContent(value) {
    this.$emit('input', value,this.indexVal);
  }
  get publicFilesUrl(){
    return UserStoreModule.userInfo.publicFilesUrl;
  }
  get containerWidth() {
    const width = this.width;
    // Test matches `100`, `'100'`
    if (/^[\d]+(\.[\d]+)?$/.test(width.toString())) {
      return `${width}px`;
    }
    return width;
  }

  get initOptions() {
    return {
      selector: `#${this.id}`,
      height: this.height,
      body_class: 'panel-body ',
      object_resizing: false,
      toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
      menubar: this.menubar,
      plugins: this.plugins,
      language: this.language,
      language_url:
        this.language === 'en' ? '' : `/tinymce/langs/${this.language}.js`,
      skin_url: `/tinymce/skins/`,
      emoticons_da:
        this.language === 'en' ? '' : `/tinymce/langs/${this.language}.js`,
      emoticons_database_url: `/tinymce/emojis.min.js`,
      end_container_on_empty_block: true,
      powerpaste_word_import: 'clean',
      code_dialog_height: 450,
      code_dialog_width: 1000,
      advlist_bullet_styles: 'square',
      advlist_number_styles: 'default',
      // imagetools_cors_hosts: ['www.tinymce.com', 'codepen.io'],
      default_link_target: '_blank',
      link_title: false,
      paste_data_images: true,
      // images_upload_base_path: '/some/basepath',
      // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
      nonbreaking_force_tab: true,
      // https://www.tiny.cloud/docs-3x/reference/configuration/Configuration3x@convert_urls/
      // https://stackoverflow.com/questions/5196205/disable-tinymce-absolute-to-relative-url-conversions
      convert_urls: false,
      init_instance_callback: (editor: any) => {
        if (this.value) {
          editor.setContent(this.value);
        }
        this.hasInit = true;
        editor.on('NodeChange Change KeyUp SetContent', () => {
          this.hasChange = true;
          this.$emit('input', editor.getContent());
        });
      },
      setup: (editor: any) => {
        editor.on('FullscreenStateChanged', (e: any) => {
          this.fullscreen = e.state;
        });
      },
      images_upload_handler: (blobInfo: any, succFun: (filePath: string) => any, failFun: (text: string) => any) => {
        const url = this.uploadUrl;
        const file = blobInfo.blob();
        const xhr = new XMLHttpRequest();
        const formData = new FormData();
        formData.append('files', file, file.name);

        request({
          url,
          method: 'post',
          data: formData,
          timeout: 15000,
        }).then((repXhr) => {
          if (!repXhr.data.data || typeof repXhr.data.data[0].filePath !== 'string') {
            failFun('Invalid JSON: ' + repXhr.data.message);
            return;
          }
          succFun(this.publicFilesUrl+repXhr.data.data[0].filePath);
        }).catch((errXhr) => {
          failFun('Invalid JSON: ' + errXhr.responseText);
        });
      }
    };
  }

  @Watch('language')
  private onLanguageChange() {
    const tinymceManager = (window as any).tinymce;
    const tinymceInstance = tinymceManager.get(this.id);
    if (this.fullscreen) {
      tinymceInstance.execCommand('mceFullScreen');
    }
    if (tinymceInstance) {
      tinymceInstance.destroy();
    }
    this.$nextTick(() => tinymceManager.init(this.initOptions));
  }
  // 图片列表？（还没被用过）
  private imageSuccessCBK(arr: TUploadObject[]) {
    const tinymce = (window as any).tinymce.get(this.id);
    arr.forEach((v) => {
      tinymce.insertContent(`<img class="wscnph" src="${v.url}" >`);
    });
  }
}
