


































import i18n from '@/lang/index';
import { Component, Vue,Prop } from 'vue-property-decorator';

@Component({name: 'vueCropper'})
export default class extends Vue {
  // 接收上传图片
  @Prop({default: ''}) avatarUrl!: any;
  // 上传图片名称
  @Prop({default: ''}) ImagefileName!: any;
  // 上传图片类型
  @Prop({default: ''}) fileTypeOrc!: any;
  // 上传图片接口
  @Prop({ default: ''}) uploadPicApi: any;
  // 是否是logo
  @Prop({default: false }) isLogo!: Boolean;

  preview = {img: '', url: ''}
  previewStyle:any = {};
  previews: any = {
    w: '',h: ''
  }
  option: any = {
    img: this.avatarUrl,
    full: false, // 是否输出原图比例的截图
    canMove: false, // 上传图片是否可以移动
    canScale: false, // 图片是否允许滚轮缩放
    autoCrop: true, // 是否默认显示截图框
    autoCropWidth: 0, // 截图框宽度默认容器的80%	0~max
    autoCropHeight: 0, // 截图框高度
    centerBox: true, //截图框是否被限制在图片里面
    fixedBox: false, //是否固定截图框大小，不允许改变
    original: false, // 上传图片是否显示原始宽高，可以铺满
    fixed: true, // 是否开启截图框宽高固定比例
    fixedNumber: [10, 7] // 宽高比例
  }
  // 预览
  realTime(data: any) {
    const previews = data;
    this.previewStyle = {
    width: previews.w + "px",
    height: previews.h + "px",
    overflow: "hidden",
    margin: "0",
    zoom: 280 / previews.h // 固定280高度
    }
    this.previews = data;
  }

  // 获取截取图片的数据
  uploadImg() {
    // 获取截图的blob数据
    (this.$refs.cropper as any).getCropBlob((data: any) => {
      const idx = this.ImagefileName.indexOf(this.fileTypeOrc);
      let filename = this.ImagefileName.slice(0, idx) + '_show' + this.ImagefileName.slice(idx);
      // 形成文件形式
      let files = new File([data],filename)
      this.$emit('imgFiles', files);
    })
  }
}
